// new certificate background maker
@each $type in $certBg {
  .cert-#{$type} {
    background-image: url("../assets/img/bg_#{$type}.png");
    background-size: cover;
    height: auto;
    width: 100%;
  }
}

//logo color hover maker
@each $name, $color in $logo-colors {
  ##{$name}-logo-undefined:hover {
    fill: $color;
  }

  ##{$name}-logo-active {
    fill: $color;
  }
}
