.iconSlide {
  position: relative;
  margin-top: 9rem;
  margin-bottom: 9rem;
}

.newCert {
  height: 87px;
  width: 199px;
  position: relative;
}

.avCert {
  width: 40%;
  height: auto;
  border-radius: 50%;
  top: 3%;
}

.logo-img {
  max-width: 60px;
  height: auto;
}
.logo-text {
  font-family: Montserrat;
  font-weight: 800;
}

.hero {
  background-image: url(../assets/img/hero_bg_item_2.svg),
    url(../assets/img/award.svg), url(../assets/img/gem.svg),
    url(../assets/img/hand-thumbs-up.svg);
  background-size: 50rem, 40px, 40px, 40px;
  background-repeat: no-repeat;
  background-position: -10% 70%, 10% 10%, 50% 40%, 10% 70%;
}
.z-1 {
  z-index: -1;
}

.divider {
  height: 10px;
}
.hero-bg {
  background-image: url(../assets/img/hero_bg_item_1.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 20%;
}

// SEARCH BAR
.search {
  min-height: 60px;
}

//slider logos color

.slide-logos {
  fill: $my-secondary;
}
:root {
  --swiper-theme-color: #2a6c9e;
}
.swiper-button-prev,
.swiper-button-next {
  top: 25%;
}

//courses slider

.swiper-container-courses {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: hidden;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  max-width: 705px;
  max-height: 397px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.logo-about {
  max-width: 100px;
  max-height: 100px;
}

.course-hero {
  background-image: url(../assets/img/Background.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.list-avatar {
  max-width: 60px;
  max-height: 60px;
}
.list-avatar-best {
  max-width: 120px;
  max-height: 120px;
}
.team-img {
  max-width: 600px;
  max-height: 600px;
  @media (max-width: 576px) {
    max-width: 300px;
    max-height: 300px;
  }
}

.about-bg {
  background-image: url(../assets/img/bg_lins.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.profile-avatar {
  max-width: 250px;
  max-height: 250px;
  // @media (max-width: 768px) {
  //   max-width: 400px;
  //   max-height: 400px;
  // }
}

.translate-sm-middle-y {
  @media (max-width: 767px) {
    transform: translateY(-50%) !important;
  }
}

.cert-img {
  @media (min-width: 768px) {
    max-width: 80%;
    height: auto;
  }
}

.course-toggal{
  max-width: 95px;
  max-height: 95px;
  cursor: pointer;
}
.course-active{
  border: #2a6c9e 3px solid;
}

.feature-course{
  object-fit: contain;
  max-width: 700px;
  height: auto;
}
.contact-cover{
  background: linear-gradient(rgba(2, 111, 194,.6), rgba(42, 108, 158,.8)), url(../assets/img/contact.jpg);
}
.mini-icone{
  width: 25px;
  height: auto;
}

.my-mb{
  margin-bottom: 10rem !important;
}
.my-mt{
  margin-top: 10rem !important;
}