// list for new certificate background add the course name and add image with the name bg_" course name " to the assets/img

$certBg: (
  angular,
  html,
  node,
  c_sharp,
  vue,
  laravel,
  graphql,
  react,
  spring,
  symfony4,
  symfony3,
  js,
  git,
  php,
  python
);

// my colors

$my-primary: #026fc2;
$my-secondary: #2a6c9e;
$my-success: #3d9970;
$my-info: #d6edff;
$my-warning: #ffae00;
$my-danger: #ff4136;
$my-light: #edf1f3;
$my-dark: #202020;
$my-gray: #9dafbd;

// bootstrap theme mode

$theme-colors: (
  "primary": $my-primary,
  "secondary": $my-secondary,
  "success": $my-success,
  "info": $my-info,
  "warning": $my-warning,
  "danger": $my-danger,
  "light": $my-light,
  "dark": $my-dark,
  "my-gray": $my-gray,
);

// LOGOS Color list

$logo-colors: (
  "angular": #dd0031,
  "vue": #41b883,
  "js": #f7df1e,
  "html": #e44d26,
  "c-sharp": #9a4993,
  "git": #f05033,
  "node-js": #8bc500,
  "laravel": #ff2d20,
  "spring": #8bc500,
  "react": #53c1de,
  "symfony-4": #000000,
  "symfony-3": #000000,
  "graphql": #e535ab,
  "php": #0b0c0e,
);
